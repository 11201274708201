import React from "react";
import { Row, Col, Card, Select } from 'antd';
import ResultPalmTreeTable from "./ResultPalmTreeTable";
import CurrentStockTable from "./CurrentStockTable";
import BunchesOnPalmTreeTable from "./BunchesOnPalmTreeTable";
import InforesenceStatusBar from "./InforesenceStatusBar";
import useDropdownItem from "../../hooks/useDropdownItem";
import { URL_COMMON } from "../../constants/urls";

export default function DashboardInfoView() {
  const productionTypeDropdown = useDropdownItem({ url: URL_COMMON.PRODUCTION_TYPE, label: 'name', pagination: true, params: { page_size: 999 } })
  const [resultPalmTreeProductionType, setResultPalmTreeProductionType] = React.useState(null)

  React.useEffect(() => {
    productionTypeDropdown.fetchOptions();
  }, [])

  return(
    <Row gutter={[16, 16]}>
      <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
        <Card title="สรุปต้นพันธุ์" bordered={false} 
          extra={
            <Select
              placeholder={"เลือกสถานะผลิต"}
              initialvalues={''}
              autoComplete='off'
              filterOption={false}
              allowClear={true}
              options={productionTypeDropdown.options}
              loading={productionTypeDropdown.loading}
              onChange={(value) => setResultPalmTreeProductionType(value)} />
          }>
          <ResultPalmTreeTable productionType={resultPalmTreeProductionType}/>
        </Card>
      </Col>

      <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
        <Card title="สต๊อกเกสร" bordered={false}>
          <CurrentStockTable/>
        </Card>
      </Col>

      <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
        <Card title="ช่อดอกบนต้นพันธุ์" bordered={false}>
            <InforesenceStatusBar /> 
        </Card>
      </Col>

      <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
        <Card title="ทะลายบนต้นพันธุ์" bordered={false}>
          <BunchesOnPalmTreeTable/> 
        </Card>
      </Col>
  </Row>
  
  )
}   